<!--
 * @Author: niweixing
 * @Date: 2021-11-01 14:32:37
 * @LastEditors: niweixing
 * @LastEditTime: 2023-02-13 10:19:22
 * @Description: 
-->
<template>
    <router-view></router-view>
</template>
<script>
export default {
	name: 'Schedule'
}
</script>